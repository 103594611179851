import { AdyenProps, AdyenProvider } from 'pages/components/AdyenProvider';
import { NSProvider } from 'pages/components/NSProvider';
// eslint-disable-next-line no-restricted-imports
import { NSProviderData } from 'useQuery/useOrganisations';

interface OrganisationProvidersProps {
  organisationId: string;
  providersData: { [key: string]: object };
}

function OrganisationProviders({ organisationId, providersData }: OrganisationProvidersProps) {
  return (
    <>
      <NSProvider nsData={providersData['ns'] as NSProviderData} organisationId={organisationId} />
      <AdyenProvider
        organisationId={organisationId}
        adyenData={providersData['adyen'] as AdyenProps}
      />
    </>
  );
}

export { OrganisationProviders };
