import React from 'react';
import { Box } from '@mui/material';

import { Page, Table } from 'components';
import { getNumberOfPages } from 'core/shared';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'hooks/useSearchParams';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

function ProductsPage() {
  const translations = useTranslations('productsPage');
  const [page, setPage] = React.useState(1);
  const { navigate } = useNavigate();

  const { searchParams, setSearchParams } = useSearchParams('products');

  React.useEffect(() => {
    if (searchParams.page) {
      setPage(searchParams.page);
    }
  }, [searchParams]);

  const { data: productsData, isFetching } = UseQuery.products({ page });

  return (
    <Page
      name="products"
      actions={[
        {
          title: translations.add,
          mode: 'closeOnClick',
          onClick: () => {
            navigate({
              name: 'product',
              id: 'new',
              searchParams: undefined,
              metaKey: false,
            });
          },
          svgName: 'add',
        },
      ]}
    >
      <Box mb={2}>{translations.billingFrequencyNote}</Box>
      <>
        <Table
          columns={{
            name: { name: translations.name, type: 'text', width: 200 },
            priceExlVAT: { name: translations.priceExlVAT, type: 'price', width: 150 },
            priceIncVAT: { name: translations.priceIncVAT, type: 'price', width: 150 },
            vatAmount: { name: translations.vatAmount, type: 'price', width: 150 },
            vatDescription: { name: translations.vatDescription, type: 'text', width: 200 },
            vatPercentage: { name: translations.vatPercentage, type: 'text', width: 200 },
            billingFrequency: { name: translations.billingFrequency, type: 'text', width: 150 },
          }}
          rows={(productsData?.result ?? []).map(
            ({
              id,
              name,
              vat,
              vat_amount,
              net_unit_price,
              gross_unit_price,
              billing_frequency,
            }) => ({
              id,
              name: name ?? '',
              priceExlVAT: net_unit_price,
              priceIncVAT: gross_unit_price,
              vatAmount: vat_amount ?? 0,
              vatDescription: vat?.name ?? '',
              vatPercentage: `${vat?.percentage.toString() ?? ''}%`,
              billingFrequency: billing_frequency.toString(),
            }),
          )}
          onClickRow={({ id, metaKey }) => {
            navigate({
              name: 'product',
              id: id.toString(),
              searchParams: undefined,
              metaKey,
            });
          }}
          pagination={{
            count: getNumberOfPages({ meta: productsData?.meta }),
            page,
            onChange: (page) => {
              setSearchParams({
                page,
              });
              setPage(page);
            },
          }}
          loading={isFetching}
        />
      </>
    </Page>
  );
}

export { ProductsPage };
