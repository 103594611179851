// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta, Null } from 'types/shared';
import validator from 'validator';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';

interface Organisation {
  active: boolean;
  allow_funds_transfer: boolean;
  allow_payment_instruments: boolean;
  booking_note_enabled: boolean;
  coc_number: string;
  community: {
    description: string;
    id: string;
    name: string;
  };
  contact: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  delegate_payment_to_employee: boolean;
  emission_report_enabled: boolean;
  enable_travel_cards_by_default: boolean;
  id: string;
  kilometer_registration_enabled: boolean;
  kyc_check_status: string;
  name: string;
  payment_instruments_allowed: boolean;
  reservations_notifications_enabled: boolean;
  reservations_notifications_email: string;
  outside_office_hours: boolean;
  commissions: {
    label: string;
    vehicle_provider_id: string;
    commission_percent: number;
  }[];
  products: {
    id: string;
    name: string;
    net_unit_price: number;
    vat: { id: string; name: string; percentage: number };
    vat_amount: number;
    vat_id: string;
  }[];
  reference: string;
  stripe_customer_id: string;
  transaction_note_enabled: boolean | null;
  transaction_rules: { MERCHANT_CATEGORY_CODES: string[] };
  vat_number: string;
  connections: {
    afas:
      | {
          connector_name: string;
          token: string;
          environment_type: string;
          environment_id: number;
        }
      | false;
  };
  providers_data: { [key: string]: object };
  top_spenders_enabled: boolean;
  employee_dashboard_enabled: boolean;
  invite_email_method: 'APP' | 'MOBILITY_CENTER' | 'APP_AND_MOBILITY_CENTER' | undefined;
  email_templates: Record<string, string>[];
  email_template_types: string[];
}

interface Commission {
  label: string;
  vehicle_provider_id: string;
  commission_percent: number;
}

interface UseOrganisationsProps {
  page: number;
  limit?: number;
  query?: string;
}

function useOrganisations({ page, limit, query }: UseOrganisationsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  const { hasMobileView } = useHasMobileView();

  interface Response {
    result: Null<Organisation>[];
    meta: Meta;
  }

  interface Params {
    page: number;
    limit: number | undefined;
    q: string;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/organisations',
      token,
      params: {
        page,
        limit: hasMobileView && limit === undefined ? 15 : limit,
        q: query ?? '',
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['organisations', { page, limit, hasMobileView, query }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

interface UseOrganisationProps {
  id: string;
}

function useOrganisation({ id }: UseOrganisationProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: Null<Organisation>;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: `/admin/organisation/${id}`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['organisations', { id }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && validator.isUUID(id),
      retry: 0,
      select: ({ data }) => data,
    },
  );
}

function useEditOrganisation() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Data {
    coc_number: string;
    active: boolean;
    contact: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
    };
    name: string;
    vat_number: string;
    stripe_customer_id: string;
    community_id: string;
    invite_email_method: string;
    products: string[];
    booking_note_enabled: boolean;
    transaction_note_enabled: boolean;
    allow_payment_instruments: boolean;
    kilometer_registration_enabled: boolean;
    emission_report_enabled: boolean;
    delegate_payment_to_employee: boolean;
    reservations_notifications_enabled: boolean;
    enable_travel_cards_by_default: boolean;
    outside_office_hours: boolean;
    top_spenders_enabled: boolean;
    employee_dashboard_enabled: boolean;
  }

  interface EditOrganisationProps {
    id: string;
    data: Data;
  }

  async function editOrganisation({ id, data: localData }: EditOrganisationProps) {
    const { data } = await API.put<void, Data>({
      path: `/admin/organisation/${id}`,
      token,
      version: 1,
      body: {
        ...localData,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, EditOrganisationProps>(
    (data: EditOrganisationProps) => editOrganisation(data),
    {
      onSuccess: async (_, { id }) => {
        await queryClient.invalidateQueries(['organisation', { id }]);
        await queryClient.invalidateQueries(['organisations']);
      },
    },
  );
}

function useResendInvites() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface ResendInvitesProps {
    id: string;
  }

  async function resendInvites({ id }: ResendInvitesProps) {
    const { data } = await API.post<void, undefined>({
      path: `/organisations/${id}/send-invites`,
      token,
      version: 1,
      body: undefined,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, ResendInvitesProps>((data: ResendInvitesProps) =>
    resendInvites(data),
  );
}

function useEditAFAS() {
  const queryClient = useQueryClient();
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface EditAFASProps {
    id: string;
    connectorName: string;
    environmentId: string;
    token: string;
    environmentType: string;
  }

  interface Body {
    connector_name: string;
    environment_id: string;
    token: string;
    environment_type: string;
  }

  async function editAFAS({
    id,
    connectorName,
    environmentId,
    token: localToken,
    environmentType,
  }: EditAFASProps) {
    const { data } = await API.post<void, Body>({
      path: `/admin/organisation/${id}/afas`,
      token,
      version: 1,
      body: {
        connector_name: connectorName,
        environment_id: environmentId,
        token: localToken,
        environment_type: environmentType,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, EditAFASProps>((data: EditAFASProps) => editAFAS(data), {
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries(['organisation', { id }]);
      await queryClient.invalidateQueries(['organisations']);
    },
  });
}

function useAddUser() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface AddUserProps {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  }

  interface Body {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  }

  async function addUser({ id, firstName, lastName, phoneNumber, email }: AddUserProps) {
    const { data } = await API.post<void, Body>({
      path: `/organisations/${id}/users`,
      token,
      version: 1,
      body: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        email: email,
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, AddUserProps>((data: AddUserProps) => addUser(data));
}

function useAddOrganisation() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface AddOrganisationProps {
    name: string;
    paymentToEmployee: boolean;
    bookingNote: boolean;
    emissionReportAccess: boolean;
    kilometerRegistration: boolean;
    CoCNumber: string;
    VAT: string;
    stripeCustomerId: string;
    communityId: string;
    inviteEmailMethod: string;
    contact: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
  }

  interface Body {
    name: string;
    delegate_payment_to_employee: boolean;
    booking_note_enabled: boolean;
    emission_report_enabled: boolean;
    kilometer_registration_enabled: boolean;
    coc_number: string;
    vat_number: string;
    stripe_customer_id: string;
    community_id: string;
    invite_email_method: string;
    contact: {
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
    };
  }

  interface Response {
    result: Null<Organisation>;
    meta: Meta;
  }

  async function addOrganisation({
    name,
    paymentToEmployee,
    bookingNote,
    emissionReportAccess,
    kilometerRegistration,
    CoCNumber,
    VAT,
    stripeCustomerId,
    communityId,
    inviteEmailMethod,
    contact,
  }: AddOrganisationProps) {
    const { data } = await API.post<Response, Body>({
      path: `/admin/organisation`,
      token,
      version: 1,
      body: {
        name,
        delegate_payment_to_employee: paymentToEmployee,
        booking_note_enabled: bookingNote,
        emission_report_enabled: emissionReportAccess,
        kilometer_registration_enabled: kilometerRegistration,
        coc_number: CoCNumber,
        vat_number: VAT,
        stripe_customer_id: stripeCustomerId,
        community_id: communityId,
        invite_email_method: inviteEmailMethod,
        contact: {
          first_name: contact.firstName,
          last_name: contact.lastName,
          email: contact.email,
          phone_number: contact.phoneNumber,
        },
      },
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Response, unknown, AddOrganisationProps>((data: AddOrganisationProps) =>
    addOrganisation(data),
  );
}

function useExportOrganisation() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface ExportOrganisationProps {
    id: string;
  }

  async function exportOrganisation({ id }: ExportOrganisationProps) {
    const { data } = await API.get<Blob, undefined, true>({
      path: `/organisations/${id}/reservations`,
      token,
      version: 1,
      params: undefined,
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown, ExportOrganisationProps>((data) => exportOrganisation(data));
}

function useHasBulkInvites({ id }: UseOrganisationProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  function fetchData() {
    return API.get<boolean, undefined, false>({
      path: `/admin/organisation/${id}/bulk-invites/check`,
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<boolean>, unknown, boolean>(['bulk_invites', { id }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token && validator.isUUID(id),
    retry: 0,
    select: ({ data }) => data,
  });
}

function useDownloadBulkInvites() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface DownloadBulkInvitesProps {
    id: string;
  }

  async function downloadBulkInvites({ id }: DownloadBulkInvitesProps) {
    const { data } = await API.get<Blob, undefined, true>({
      path: `/admin/organisation/${id}/bulk-invites/download`,
      token,
      version: 1,
      params: undefined,
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<Blob, unknown, DownloadBulkInvitesProps>((data) => downloadBulkInvites(data));
}

function useUpdateOrganisationCommissions() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface UpdateOrganisationCommissionsProps {
    id: string;
    commissions: Commission[];
  }

  interface Body {
    commissions: Commission[];
  }

  async function updateOrganisationCommissions({
    id,
    commissions,
  }: UpdateOrganisationCommissionsProps) {
    const { data } = await API.put<void, Body>({
      path: `/admin/organisation/${id}/provider-commissions`,
      token,
      body: { commissions: commissions },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UpdateOrganisationCommissionsProps>(
    ({ id, commissions }: UpdateOrganisationCommissionsProps) =>
      updateOrganisationCommissions({ id, commissions }),
  );
}

const deliveryOptions = ['Office', 'Employee'] as const;
type NSCardDeliveryOption = (typeof deliveryOptions)[number];

interface NSCardDeliveryAddress {
  street: string;
  house_number: string;
  addition?: string;
  zipcode: string;
  city: string;
  country_code: string;
}

interface NSProviderData {
  agreement: string;
  department: Record<string, string>;
  company: string | undefined;
  card_delivery_option: NSCardDeliveryOption;
  split_billing?: boolean;
  office_address?: NSCardDeliveryAddress;
}

interface OrganisationProviders {
  NS?: NSProviderData;
  ADYEN?: {
    currency: string;
    auto_top_up_amount?: number;
    notification_threshold?: number;
  };
}

function useUpdateOrganisationProviders() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface UpdateOrganisationProvidersProps {
    id: string;
    providers: OrganisationProviders;
  }

  async function updateOrganisationProviders({ id, providers }: UpdateOrganisationProvidersProps) {
    interface Body {
      providers: OrganisationProviders;
    }

    const { data } = await API.put<void, Body>({
      path: `/admin/organisation/${id}/providers`,
      token,
      body: { providers: providers },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UpdateOrganisationProvidersProps>(
    ({ id, providers }: UpdateOrganisationProvidersProps) =>
      updateOrganisationProviders({ id, providers }),
  );
}

function useUpdateOrganisationEmailTemplates() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface UpdateOrganisationEmailTemplateProps {
    id: string;
    email_templates: Record<string, string>;
  }

  async function updateOrganisationEmailTemplates({
    id,
    email_templates,
  }: UpdateOrganisationEmailTemplateProps) {
    interface Body {
      email_templates: Record<string, string>;
    }

    const { data } = await API.put<void, Body>({
      path: `/admin/organisation/${id}/email_templates`,
      token,
      body: { email_templates },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(data);
  }

  return useMutation<void, unknown, UpdateOrganisationEmailTemplateProps>(
    ({ id, email_templates }: UpdateOrganisationEmailTemplateProps) =>
      updateOrganisationEmailTemplates({ id, email_templates }),
  );
}

export {
  type Commission,
  deliveryOptions,
  type NSCardDeliveryAddress,
  type NSCardDeliveryOption,
  type NSProviderData,
  type Organisation,
  useAddOrganisation,
  useAddUser,
  useDownloadBulkInvites,
  useEditAFAS,
  useEditOrganisation,
  useExportOrganisation,
  useHasBulkInvites,
  useOrganisation,
  useOrganisations,
  useResendInvites,
  useUpdateOrganisationCommissions,
  useUpdateOrganisationEmailTemplates,
  useUpdateOrganisationProviders,
};
