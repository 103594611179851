import React from 'react';
import { Box, Unstable_Grid2 as Grid, useTheme } from '@mui/material';

import { Button, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';
// eslint-disable-next-line no-restricted-imports
import { Organisation } from 'useQuery/useOrganisations';

interface OrganisationEmailProps {
  organisationId: string;
  emailTemplates: Organisation['email_templates'];
  allowedEmailTemplateTypes: Organisation['email_template_types'];
}

function OrganisationEmail({
  organisationId,
  emailTemplates,
  allowedEmailTemplateTypes,
}: OrganisationEmailProps) {
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();
  const translations = useTranslations('organisationEmail');
  const { addToast } = useToast();

  const {
    mutateAsync: mutateAsyncUpdateOrganisationTemplate,
    isLoading: loadingOrganisationEmailTemplates,
  } = UseMutation.updateOrganisationEmailTemplates();
  const { refetch: refetchOrganisationData, isLoading: loadingOrganisationData } =
    UseQuery.organisation({ id: organisationId });

  const [localEmailTemplates, setLocalEmailTemplates] = React.useState(() =>
    allowedEmailTemplateTypes.map((type) => {
      const matchingTemplate = emailTemplates.find((template) => template.type === type);
      return {
        type,
        text: matchingTemplate ? matchingTemplate.text : '',
      };
    }),
  );

  function handleInputChange(index: number, value: string) {
    setLocalEmailTemplates((prevTemplates) => {
      const updatedTemplates = [...prevTemplates];
      updatedTemplates[index] = { ...updatedTemplates[index], text: value };
      return updatedTemplates;
    });
  }

  function parseType(type: string) {
    return type
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const initialEmailTemplates = React.useMemo(
    () =>
      allowedEmailTemplateTypes.map((type) => {
        const matchingTemplate = emailTemplates.find((template) => template.type === type);
        return {
          type,
          text: matchingTemplate ? matchingTemplate.text : '',
        };
      }),
    [emailTemplates, allowedEmailTemplateTypes],
  );

  const disableSave = React.useMemo(() => {
    return localEmailTemplates.every(
      (template, index) => template.text === initialEmailTemplates[index]?.text,
    );
  }, [localEmailTemplates, initialEmailTemplates]);

  async function save() {
    const formattedTemplates = localEmailTemplates.reduce(
      (acc: Record<string, string>, template: { text: string; type: string }) => {
        acc[template.type] = template.text;
        return acc;
      },
      {},
    );

    try {
      await mutateAsyncUpdateOrganisationTemplate({
        id: organisationId,
        email_templates: formattedTemplates,
      });

      await refetchOrganisationData();
      addToast({ title: translations.updatedSuccessfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
          marginBottom: 2,
          border: 1,
          borderColor: 'divider',
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
          flexDirection: 'column',
        }}
      >
        <Box sx={{ paddingBottom: 1 }}>
          <Text.LargeBold>{translations.title}</Text.LargeBold>
          <Text.Small color="warning.light">{translations.description}</Text.Small>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {localEmailTemplates.map((template, index) => (
              <Box
                key={template.type}
                sx={{ marginBottom: index < localEmailTemplates.length - 1 ? 4 : 0 }}
              >
                <Box sx={{ paddingLeft: 1, paddingBottom: 1 }}>
                  <Text.Bold>{parseType(template.type)}</Text.Bold>
                </Box>
                <TextField
                  fullWidth
                  type="text"
                  label=""
                  value={template.text}
                  onChange={(value) => handleInputChange(index, value)}
                />
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
        }}
      >
        <Button
          label={translations.save}
          disabled={disableSave || loadingOrganisationEmailTemplates || loadingOrganisationData}
          loading={loadingOrganisationEmailTemplates || loadingOrganisationData}
          onClick={save}
        />
      </Grid>
    </>
  );
}

export { OrganisationEmail };
